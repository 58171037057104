import React, {FC, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Image} from "antd";
import {IStore} from "../../../../redux/IStore";
import {connect} from "react-redux";
import {ISetupStateUpdate} from "../../../../redux/reducers/Setup";
import {update} from "../../../../redux/actions/Setup";
import {IUser} from "../../../../api/entity/security/IUser";
import FileService from "../../../../api/service/FileService";
import {ISipRealization} from "../../../../api/entity/home/ISipRealization";

interface IProps {
    sipRealizations: ISipRealization[],
    update: (changes: ISetupStateUpdate) => void,
    user?: IUser
}

const SipRealizationDetail: FC<IProps> = ({sipRealizations}) => {
    const params = useParams();
    const [house, setHouse] = useState<ISipRealization>();

    const navigate = useNavigate()

    useEffect(() => {
        const name = params['house']
        const house = sipRealizations.find(h => h?.location.replaceAll(' ', '_') === name)
        if (house) {
            setHouse(house)
        } else if (sipRealizations.length) {
            navigate('/not-found')
        }
    }, [sipRealizations]);

    return house ? <div>
            <div className="container-fluid no-padd">
                <div className="row-fluid no-padd">
                    <div className="col-sm-12 no-padd">
                        <div className="container-fluid top-banner no-padd big light no-marg-bottom" style={{
                            background: "url(" + FileService.preview(house.images[0]?.uid || '') + ")",
                            backgroundPosition: 'center',
                            backgroundSize: 'cover'
                        }}>
                            <span className="overlay"></span>
                            <div className="content">
                                <h1 className="title">{house?.location}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid no-padd">
                <div className="row-fluid no-padd">
                    <div className="col-sm-12 no-padd">
                        <div className="project-detail-gallery-outer">
                            <Image.PreviewGroup preview={{toolbarRender: () => <div></div>}}>
                                <div className="gallery-grid">
                                    {house.images.map(image => (
                                        <div className="gallery-grid-item">
                                            <div className="detail-gallery-item">
                                                <div className="detail-gallery-item-img">
                                                    <Image src={FileService.preview(image.uid)} alt={image.name}/>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Image.PreviewGroup>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        : <div>Not found</div>
}

const mapStateToProps = ({setup}: IStore) => {
    const {sipRealizations, user} = setup
    return {sipRealizations, user}
}

const mapDispatchToProps = {
    update: (changes: ISetupStateUpdate) => update(changes),
}


export default connect(mapStateToProps, mapDispatchToProps)(SipRealizationDetail);