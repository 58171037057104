import React, {FC, useEffect, useState} from "react";
import {IModularHouse} from "../../../../api/entity/home/IModularHouse";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Button, Collapse, Image} from "antd";
import {IStore} from "../../../../redux/IStore";
import {connect} from "react-redux";
import ModularForm from "../form/ModularForm";
import {ISetupStateUpdate} from "../../../../redux/reducers/Setup";
import {update} from "../../../../redux/actions/Setup";
import {IUser, ROLES} from "../../../../api/entity/security/IUser";
import {EditOutlined} from "@ant-design/icons";
import FileService from "../../../../api/service/FileService";

interface IProps {
    modularHouses: IModularHouse[],
    update: (changes: ISetupStateUpdate) => void,
    user?: IUser
}

const ModularDetail: FC<IProps> = ({modularHouses, user, update}) => {
    const params = useParams();
    const [house, setHouse] = useState<IModularHouse>();
    const [showSize, setShowSize] = useState(false);
    const [editHouse, setEditHouse] = useState<IModularHouse>();
    const [animateButton, setAnimateButton] = useState(false);

    const navigate = useNavigate()

    useEffect(() => {
        setTimeout(() => setAnimateButton(true), 500)
    }, [modularHouses]);

    const onFinish = (house?: IModularHouse) => {
        setEditHouse(undefined)
        if (house) {
            const houses = [...modularHouses]
            const index = houses.findIndex(h => h.id === house?.id)
            if (index > -1) {
                houses[index] = house
            } else {
                houses.push(house)
            }
            update({modularHouses: houses})
        }
    }

    useEffect(() => {
        const name = params['house']
        const house = modularHouses.find(h => h?.name.replaceAll(' ', '_') === name || h?.name.replaceAll(' ', '_').replaceAll('!', '') === name)
        if (house) {
            setHouse(house)
        } else if (modularHouses.length) {
            navigate('/not-found')
        }
    }, [modularHouses]);

    return house ? <div>
        {!!editHouse && (
            <ModularForm onFinish={onFinish} house={editHouse}/>
        )}
        <div
            className="shop-container container padd-only-xs product-template-default single single-product postid-2072 woocommerce woocommerce-page woocommerce-no-js wpb-js-composer js-comp-ver-5.6 responsive">
            <div className="row">
                <div className="col-sm-12 col-md-12 margin-lg-90b margin-sm-60b">
                    <div className="woocommerce-notices-wrapper"></div>
                    <div id={house.name}
                         className="post-2072 product type-product status-publish has-post-thumbnail product_cat-acssessories product_tag-casual product_tag-modern first instock virtual purchasable product-type-simple">
                        <div className="flex-item ">
                            <div
                                className="woocommerce-product-gallery woocommerce-product-gallery--with-images woocommerce-product-gallery--columns-3 images"
                                data-columns="3">
                                <Image.PreviewGroup preview={{toolbarRender: () => <div></div>}}>
                                    <div className="flex-viewport">
                                        <figure className="woocommerce-product-gallery__wrapper">
                                            <div className="woocommerce-product-gallery__image flex-active-slide">
                                                <Image src={FileService.preview(house.images[0]?.uid)} preview={{mask: false}}
                                                       draggable={false}
                                                       className="attachment-shop_single size-shop_single firstImg cursor-pointer"
                                                />
                                            </div>
                                        </figure>
                                    </div>

                                    <ol className="flex-control-nav flex-control-thumbs">
                                        {house.images.map((image, i) => (
                                            <li>
                                                <Image src={FileService.preview(image.uid)} preview={{mask: false}} draggable={false}
                                                       className={"attachment-full size-full cursor-pointer clicked_alessandria_1 " + (i === 0 ? 'flex-active' : '')}
                                                />
                                            </li>
                                        ))}
                                    </ol>
                                </Image.PreviewGroup>
                            </div>

                            <div className="summary entry-summary">
                                {user?.roles.includes(ROLES.EDITOR) && (
                                    <div className={'position-absolute'} style={{top: 5, right: 5, zIndex: 1}}>
                                        <Button shape={'circle'}
                                                onClick={() => setEditHouse(house)}
                                                icon={<EditOutlined/>} className={'mb-1'}>
                                        </Button>
                                    </div>
                                )}
                                <h2 className="product_title entry-title">{house.name}</h2>


                                <p style={{color: "#a0753b", fontSize: "55px"}} className="price"><span
                                    className="woocommerce-Price-amount amount">{Number(house.price || 0).toLocaleString()}</span><span
                                    className="woocommerce-Price-currencySymbol">Kč</span></p>

                                <Link style={{marginLeft: "8px", marginBottom: "10px"}} to="/kontakt"
                                      className={"prague-services-link a-btn-2 creative" + (animateButton ? " anima" : '')} target="_self">
                                    <span className="a-btn-line"></span>
                                    MÁM ZÁJEM </Link>
                                <div className="woocommerce-product-details__short-description">
                                    {house.roof && <p>Střecha: <strong>{house.roof}</strong></p>}
                                    {house.frame && <p>Rám: <strong>{house.frame}</strong></p>}
                                    {house.heating && <p>Topné zařízení: <strong>{house.heating}</strong></p>}
                                    {house.isolation && <p>Izolace: <strong>{house.isolation}</strong></p>}
                                    {house.wiring && <p>Zapojení: <strong>{house.wiring}</strong></p>}
                                    {house.floor && <p>Podlahové krytiny: <strong>{house.floor}</strong></p>}
                                    {house.bathroomFloor &&
                                        <p>Podlahové krytiny v koupelně: <strong>{house.bathroomFloor}</strong></p>}
                                    {house.window && <p>Okna: <strong>{house.window}</strong></p>}
                                    {house.door && <p>Dveře: <strong>{house.door}</strong></p>}
                                    {house.equipment && <p>Vybavení domu: <strong>{house.equipment}</strong></p>}
                                    {house.outerWallDecoration &&
                                        <p>Dekorace vnější stěny: <strong>{house.outerWallDecoration}</strong></p>}
                                    {house.innerWallDecoration &&
                                        <p>Dekorace vnitřní stěny a stropu: <strong>{house.innerWallDecoration}</strong>
                                        </p>}
                                    {house.note && <div><p>Další poznámky:</p>
                                        <strong>
                                            <p dangerouslySetInnerHTML={{__html: house.note || ''}}></p>
                                        </strong>
                                    </div>}
                                </div>


                                <Collapse style={{padding: 0}} defaultActiveKey={['1']}
                                          onChange={() => setShowSize(!showSize)} ghost items={[
                                    {
                                        id: 'main',
                                        showArrow: false,
                                        label: <div className="woocommerce-tabs wc-tabs-wrapper">
                                            <div className={"tabs-item-wrapp " + (showSize ? 'active' : '')}
                                                 onClick={() => setShowSize(!showSize)}>
                                                <div className="description_tab tabs-title" id="tab-title-description"
                                                     aria-controls="tab-description">
                                        <span className="tabs-mark">
                                            <span className="tabs-mark-plus">+</span>
                                            <span className="tabs-mark-minus">-</span>
                                        </span>
                                                    Rozměry
                                                </div>
                                            </div>
                                        </div>
                                        ,
                                        children: <div
                                            className="woocommerce-Tabs-panel woocommerce-Tabs-panel--description panel entry-content wc-tab"
                                            id="tab-description" aria-labelledby="tab-title-description ">
                                            {house.outerHeight && house.outerWidth && house.outerLength && (
                                                <p>Vnější: <span
                                                    style={{color: "#7e7d7d"}}>{house.outerWidth} x {house.outerLength} x {house.outerHeight} m</span>
                                                </p>
                                            )}

                                            {house.innerHeight && house.innerWidth && house.innerLength && (
                                                <p>Vnitřní: <span
                                                    style={{color: "#7e7d7d"}}>{house.innerWidth} x {house.innerLength} x {house.innerHeight} m</span>
                                                </p>
                                            )}

                                            {house.area && (
                                                <p>Výměra: <span style={{color: "#7e7d7d"}}>{house.area} m2</span></p>
                                            )}

                                        </div>
                                    }
                                ]}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> : <></>
}

const mapStateToProps = ({setup}: IStore) => {
    const {modularHouses, user} = setup
    return {modularHouses, user}
}

const mapDispatchToProps = {
    update: (changes: ISetupStateUpdate) => update(changes),
}


export default connect(mapStateToProps, mapDispatchToProps)(ModularDetail);