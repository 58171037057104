import {Link} from "react-router-dom";
import {IRecordsOrder} from "../../../api/entity/home/IRecordsOrder";
import {ISetupState} from "../../../redux/reducers/Setup";
import React, {FC, useEffect, useState} from "react";
import RecordsOrderService, {
    REALIZATION_ORDER,
    SIP_REALIZATION_ORDER
} from "../../../api/service/home/RecordsOrderService";
import {Alert, Button, Col, Image, Popconfirm, Row} from "antd";
import {DeleteOutlined, DragOutlined, EditOutlined, EyeInvisibleOutlined, PlusOutlined} from "@ant-design/icons";
import {IStore} from "../../../redux/IStore";
import {update} from "../../../redux/actions/Setup";
import {connect} from "react-redux";
import SortableContainer from "../../../shared/structure/sortable/SortableContainer";
import SortableItem from "../../../shared/structure/sortable/SortableItem";
import RealizationService from "../../../api/service/home/RealizationService";
import {IUser, ROLES} from "../../../api/entity/security/IUser";
import FileService from "../../../api/service/FileService";
import EditableText from "./component/EditableText";
import {ISipRealization} from "../../../api/entity/home/ISipRealization";
import SipRealizationForm from "./form/SipRealizationForm";
import SipRealizationService from "../../../api/service/home/SipRealizationService";

interface IProps {
    sipRealizations: ISipRealization[],
    recordsOrder: IRecordsOrder[]
    update: (changes: { [key in keyof ISetupState]?: ISetupState[key] }) => void
    user?: IUser
}

const SipRealization: FC<IProps> = ({sipRealizations, update, recordsOrder, user}) => {

    const [editHouse, setEditHouse] = useState<ISipRealization>();

    useEffect(() => {
        let result: ISipRealization[] = []

        const main = recordsOrder.find(m => m.id === SIP_REALIZATION_ORDER)
        if (main) {
            sipRealizations.forEach(function (a) {
                if (main.order.indexOf(a.id) > -1) {
                    result[main.order.indexOf(a.id)] = a
                }
            });
            sipRealizations.forEach(function (a) {
                if (main.order.indexOf(a.id) === -1) {
                    result.push(a)
                }
            });
            update({sipRealizations: result})
        }

    }, [recordsOrder]);

    const editNew = () => {
        setEditHouse({id: '', location: '', images: []})
    }

    const onFinish = (house?: ISipRealization) => {
        setEditHouse(undefined)
        if (house) {
            const houses = [...sipRealizations]
            const index = houses.findIndex(h => h.id === house?.id)
            if (index > -1) {
                houses[index] = house
            } else {
                houses.push(house)
            }
            update({sipRealizations: houses})
        }
    }

    const onDragEnd = (ids: string[]) => {
        let result: ISipRealization[] = []

        sipRealizations.forEach(function (a) {
            result[ids.indexOf(a.id)] = a
        });
        RecordsOrderService.create({id: SIP_REALIZATION_ORDER, order: result.map(h => h.id)}).then(order => {
            let temp = [...recordsOrder]
            const index = temp.findIndex(o => o.id === order.id)
            temp[index] = order
            update({recordsOrder: temp})
        })

        update({sipRealizations: result})
    }

    function remove(house: ISipRealization) {
        SipRealizationService.delete(house.id).then(() => {
            update({sipRealizations: sipRealizations.filter(h => h.id !== house.id)})
        })
    }

    function renderHouse(ref: (node: (HTMLElement | null)) => void, styles: React.CSSProperties, isDragging: boolean, realization: ISipRealization, props: any) {
        return <div ref={ref}
                    key={realization.images[0]?.name}
                    style={{...styles}}
                    className={'gallery-grid-item ' + (isDragging ? 'is-dragging p-0' : '')}>
            <div className={"portfolio-item-wrapp"}>
                <div className="portfolio-item">
                    <div className="project-masonry-wrapper">
                        <Link className="project-masonry-item-img-link"
                              to={"/sip/reference/" + encodeURI(realization.location.replaceAll(' ', '_'))}>
                            <div className={'project-masonry-item-img'}>
                                {realization.isPublic === false && <div className="overlay z-index-1">
                                    <Alert icon={<EyeInvisibleOutlined style={{color: 'black'}}/>}
                                           style={{backgroundColor: '#c7c7c7'}} message="Neveřejný" banner/>
                                </div>}
                                <Image key={realization.images[0]?.name}
                                       src={FileService.preview(realization.images[0]?.uid)}
                                       alt={realization.location}
                                       className={'scale'}
                                       wrapperClassName={'w-100'}
                                       preview={false}
                                />
                            </div>


                            <div className="project-masonry-item-content">
                                <h4 className="project-masonry-item-title mb-4">{realization.location}</h4>
                            </div>
                        </Link>
                        {user?.roles.includes(ROLES.EDITOR) && (
                            <div className={'position-absolute'} style={{top: 5, right: 5, zIndex: 1}}>
                                <Col className={'flex flex-column'}>
                                    <Button shape={'circle'}
                                            onClick={() => setEditHouse(realization)}
                                            icon={<EditOutlined/>} className={'mb-1'}/>
                                    <Button shape={'circle'}  {...props} style={{cursor: 'move'}}
                                            icon={<DragOutlined/>} className={'mb-1'}/>
                                    <Popconfirm onConfirm={() => remove(realization)} title={'Opravdu smazat?'}>
                                        <Button danger={true} shape={'circle'} icon={<DeleteOutlined/>}/>
                                    </Popconfirm>
                                </Col>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    }

    const houses = sipRealizations.filter(h => user?.roles.includes(ROLES.EDITOR) || h.isPublic !== false)

    return <div>
        {!!editHouse && (
            <SipRealizationForm onFinish={onFinish} house={editHouse}/>
        )}
        <div className="container-fluid">
            <div className="row-fluid margin-lg-145t margin-sm-100t">

                <Row justify={"space-around"} className={'container padd-only-xs'} align={'middle'} wrap={false}>
                    <div></div>
                    <div className={'w-100'}>
                        <h1 style={{marginBottom: "10px"}}
                            className="title d-flex justify-content-center">ÚSPĚŠNÉ REALIZACE V RÁMCI CELÉ EVROPY</h1>
                        <div style={{marginBottom: "20px"}} className=" d-flex justify-content-center">
                            <EditableText wrapClassName={'text-center'} wrap={true} id={'sip-realizations.intro.description'} inputType={'wysiwyg'}
                                          fallback={
                                              <p>
                                                  <p> Co náš zákazník navrhne, to dodáme na stavbu
                                                      rychle a včas.</p>
                                                  <p>
                                                      Nabízíme kompletní stavební systém SIP
                                                      včetně všech potřebných materiálů pro dokončení hrubé stavby. SIP
                                                      panely zhotovujeme na míru dle projektových dokumentací ve výrobní
                                                      hale. Našim zákazníkům tak nevznikají dodatečné práce na
                                                      staveništi, šetří se čas a peníze. Zajišťujeme komfortní řízení
                                                      stavby bez zbytečného přerušování kvůli subdodávkám.
                                                  </p>
                                              </p>

                                          }/>
                        </div>
                    </div>
                    <div>
                        {user?.roles.includes(ROLES.EDITOR) && (
                            <Button shape={'circle'} onClick={editNew} icon={<PlusOutlined/>}/>
                        )}
                    </div>
                </Row>
                <div className="col-sm-12 mb-4">
                    <div className="vc_column-inner">
                        <div data-unique-key="14ad5b356d917aa464e6341724c17a46" className="js-load-more"
                             data-start-page="1" data-max-page="1" data-next-link="">
                            <div className={'gallery-grid'}>
                                <SortableContainer onSort={onDragEnd} ids={houses.map(h => h.id) || []}
                                                   mode={'grid'}
                                                   renderOverlay={id => {
                                                       const draggedHouse = houses.find(h => h.id === id)
                                                       return draggedHouse ? <SortableItem key={draggedHouse.id}
                                                                                           render={(styles, ref, isDragging, props) => renderHouse(ref, styles, isDragging, draggedHouse, props)}
                                                                                           id={draggedHouse.id}/> : <></>
                                                   }}>
                                    {houses.map((house) => (
                                        <SortableItem key={house.id}
                                                      render={(styles, ref, isDragging, props) => renderHouse(ref, styles, isDragging, house, props)}
                                                      id={house.id}/>
                                    ))}
                                </SortableContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}


const mapStateToProps = ({setup}: IStore) => {
    const {sipRealizations, recordsOrder, user} = setup
    return {sipRealizations, recordsOrder: recordsOrder, user}
};

const mapDispatchToProps = {
    update: (changes: { [key in keyof ISetupState]?: ISetupState[key] }) => update(changes),
}


export default connect(mapStateToProps, mapDispatchToProps)(SipRealization);