import {Alert, Button, Col, Form, Input, Modal, Row, Tooltip} from "antd";
import {DragOutlined, EyeInvisibleOutlined, EyeOutlined, SaveOutlined} from '@ant-design/icons'
import React, {FC, useState} from "react";
import FileUpload from "../../../../shared/input/FileUpload";
import FileService from "../../../../api/service/FileService";
import {uuid} from "../../../../shared/Utils";
import {UploadFileStatus} from "antd/es/upload/interface";
import {IFile} from "../../../../api/entity/file/IFile";
import {ISipRealization} from "../../../../api/entity/home/ISipRealization";
import SipRealizationService from "../../../../api/service/home/SipRealizationService";

interface IProps {
    house: ISipRealization
    onFinish: (house?: ISipRealization) => void
}

const SipRealizationForm: FC<IProps> = ({house, onFinish}) => {

    const [form] = Form.useForm();

    const [saving, setSaving] = useState(false);
    const [isPublic, setPublic] = useState(house.isPublic === undefined ? true : house.isPublic);


    const onSubmit = async (values?: ISipRealization) => {
        let files: IFile[] = []
        setSaving(true)
        const newImages = values?.images.filter(i => !house.images.find(image => image.uid === i.uid)) || []
        if (newImages.length) {
            const formData = new FormData()
            newImages.forEach(image => {
                if (image.originFileObj) {
                    formData.append('files[]', image.originFileObj, image.name)
                }
            })
            await FileService.upload?.(formData).then(results => files = results)
        }

        const data = {
            ...house, ...values,
            isPublic,
            images: values?.images.map(image => {
                const uploaded = files.find(f => f.label === image.name)
                return {...( uploaded ? {
                        name: uploaded.label,
                        uid: uploaded.id,
                        status: 'done' as UploadFileStatus,
                        type: uploaded.type
                    } : image)}
            }) || []
        }

        SipRealizationService.create({
            ...data,
            id: house.id || uuid()
        }).then((result: ISipRealization) => {
            onFinish(result)
            setSaving(false)
        })
    }

    return <Modal title={'Upravit'} width={800} open={true} onCancel={() => onFinish()}
                  styles={{body: {maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden'}}}
                  classNames={{footer: 'border-top'}}
                  footer={[
                      <Row key={'row'} justify={"space-between"} className={'mt-2'}>
                          <Col>
                              <Tooltip title={isPublic ? 'Skrýt tento dům' : 'Zveřejnit tento dům'}>
                                  <Button icon={isPublic ? <EyeOutlined/> :
                                      <EyeInvisibleOutlined/>} type={isPublic ? 'primary' : undefined}
                                          onClick={() => setPublic(!isPublic)}/>
                              </Tooltip>
                          </Col>
                          <Col>
                              <Button onClick={() => onFinish()} className={'mr-2'}>Zrušiť</Button>
                              <Button onClick={() => form.validateFields().then(onSubmit)} type={"primary"}
                                      icon={<SaveOutlined/>} loading={saving}>Uložiť</Button>
                          </Col>
                      </Row>
                  ]}>
        <Form layout={'vertical'} form={form} onFinish={onFinish} initialValues={{...house}} className={'w-100'}>
            <Row className={'mt-2 w-100 p-1'}>
                <Col className={'w-100'}>
                    {/*<Form.Item label={'Název'} name={'name'}*/}
                    {/*           rules={[{required: true, message: 'Políčko je vyžadováno'}]}>*/}
                    {/*    <Input/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item label={'Lokace'} name={'location'}
                               rules={[{required: true, message: 'Políčko je vyžadováno'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label={'Obrázky'} name={'images'}>
                        <FileUpload multiple={true} accept={['image/*']}/>
                    </Form.Item>
                    <Alert className={'py-2'} description={<div className={'f-small'}>
                        První obrázek je vždy profilový obrázek <br/>
                        Řadit můžete přetažením pomocí tlačítka s ikonou <DragOutlined/>
                    </div>}/>
                </Col>
            </Row>
        </Form>
    </Modal>
}

export default SipRealizationForm